import React from 'react'
import Helmet from 'react-helmet'
import { Link,graphql } from 'gatsby'
import get from 'lodash/get'

import styled from 'styled-components'

import Layout from '../components/Layout'

class RecipePostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = post.excerpt
    const { previous, next } = this.props.pageContext
    const siteKeywords = get(
      this,
      'props.data.site.siteMetadata.siteKeywords'
    )
    const siteUrl = get(
      this,
      'props.data.site.siteMetadata.siteUrl'
    )
    const heroImage = post.frontmatter.image ? require('../assets/img/headers/' + post.frontmatter.image) : require('../assets/img/headers/default.jpg');
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            //html tags
            { name: 'description', content: siteDescription },
            { name: 'keywords', content: post.frontmatter.keywords},
            { itemprop: 'name', content: post.frontmatter.title },
            { itemprop: 'description', content: siteDescription},
            { itemprop: 'image', content: siteUrl + heroImage},
            //facebook tags
            {property: 'og:title', content: post.frontmatter.title},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: siteUrl + heroImage},
            {property: 'og:url', content: siteUrl},
            {property: 'og:description', content: siteDescription},
            //twitter tags
            {property: 'twitter:card', content: 'twitter:card'},
            {property: 'twitter:title', content: post.frontmatter.title},
            {property: 'twitter:creator', content: '@likeOMGitsFEDAY'},
            {property: 'twitter:image', content: siteUrl + heroImage},
            {property: 'twitter:url', content: siteUrl},
            {property: 'twitter:description', content: siteDescription}
          ]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
        />
      <Hero style={{ backgroundImage:"url("+heroImage+")", backgroundSize:"cover"}} className="intro">
        <div className="intro-body">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h1 className="brand-heading">{post.frontmatter.title}</h1>
                        <p className="intro-text">{post.frontmatter.description}</p>
                    </div>
                </div>
            </div>
        </div>
      </Hero>
      <RecipePost>
        <div className="container">
            <p>{post.frontmatter.date}</p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />

            <hr />
            <div className="row">
              <div className="col-md-4">
                  <h6>Preparation time</h6>
                  {post.frontmatter.prepmins} mins<span className="invisible">PT{post.frontmatter.prepmins}M</span>
                  <h6>Cooking time</h6>
                  {post.frontmatter.cookmins} mins<span className="invisible">PT{post.frontmatter.cookmins}M</span>
              </div>
              <div className="col-md-8">
                <h6>Servings</h6>
                {post.frontmatter.servings}
                <h6>Macros</h6>
                <div className="macro">
                  <dt>Calories</dt><dd>{ post.frontmatter.calories }</dd>
                </div>
                <div className="macro">
                  <dt>Carbs</dt><dd>{ post.frontmatter.carbs }</dd>
                </div>
                <div className="macro">
                  <dt>Fat</dt><dd>{ post.frontmatter.fat }</dd>
                </div>
                <div className="macro">
                  <dt>Protein</dt><dd>{ post.frontmatter.protein }</dd>
                </div>
                <div className="macro">
                  <dt>Fiber</dt><dd>{ post.frontmatter.fiber }</dd>
                </div>
                <div className="macro">
                  <dt>Sugar</dt><dd>{ post.frontmatter.sugar }</dd>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
              <h3>Ingredients</h3>
              <ul>
                {post.frontmatter.ingredients.map((ingredient) => {
                  return (
                    <li key={ingredient}>{ ingredient }</li>
                  )
                })}
              </ul>
              </div>
            </div>
            <h3>Instructions</h3>
            <ol>
              {post.frontmatter.instructions.map((instruction) => {
                return (
                  <li key={instruction}>{ instruction }</li>
                )
              })}
            </ol>
              <ul
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  listStyle: 'none',
                  padding: '5em 0 1em 0',
                }}
              >
                <li>
                  {
                    previous &&
                    <Link to={previous.fields.slug} rel="prev">
                      ← {previous.frontmatter.title}
                    </Link>
                  }
                </li>
                <li>
                  {
                    next &&
                    <Link to={next.fields.slug} rel="next">
                      {next.frontmatter.title} →
                    </Link>
                  }
                </li>
              </ul>
          </div>
      </RecipePost>

      </Layout>
    )
  }
}
const Hero = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size:cover;
  padding: 20%;
`

const RecipePost = styled.div`
  background-color:#fff;
  color: #696969;
  h1 {
    text-transform: none;
    padding-top:1em;
  }
  h6 {
    marigin-top: .5em;
    margin-bottom: .5em;
  }
  .macro {
    float: left;
    display: inline-block;
    margin-right: 10px;
  }
  ul, ol {
    margin-left: 1em;
  }
  @media(min-width: 1200px){
    .container {
      max-width: 960px;
    }
  }
`
export default RecipePostTemplate

export const pageQuery = graphql`
  query RecipePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteKeywords
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        image
        description
        instructions
        ingredients
        servings
        calories
        carbs
        fat
        protein
        sugar
        fiber
        prepmins
        cookmins
      }
    }
  }
`
